<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrando</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>registros</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="8"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="query"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
              />
              <!-- <v-select
                v-model="statusFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="descripcion"
                :options="statusOptions"
                class="invoice-filter-select d-inline-block mr-1"
                placeholder="Filtros Ose"
                @input="chageStatusFilter"
              /> -->
              <b-button
                variant="primary"
                @click="openForm('new')"
              >
                <span class="text-nowrap">Nuevo</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="items"
        responsive
        :fields="fields"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No se encontraron registros"
        :busy="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner
              variant="primary"
              class="align-middle"
            />
            <strong class="ml-1">Cargando...</strong>
          </div>
        </template>
        <template #cell(razonSocial)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="`data:image/jpeg;base64,${data.item.foto}`"
                :text="avatarText(`${data.item.razonSocial}`)"
                :variant="`light-${getColorAvatar()}`"
              />
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.razonSocial }}
            </span>
            <small class="text-muted">{{ data.item.representanteLegal }}</small>
            <small class="text-muted d-block ">{{ data.item.tipoDocumento.abreviatura }} {{ data.item.documento }}</small>
          </b-media>
        </template>
        <!-- Column: estado_sunat -->
        <template #cell(estado_sunat)="data">
          <b-badge
            v-if="data.item.repuestaOse == null"
            variant="primary"
          >
            Pendiente
          </b-badge>
          <b-badge
            v-if="data.item.repuestaOse != null"
            variant="success"
          >
            Enviado
          </b-badge>

        </template>
        <!-- Column: estado_venta -->
        <template #cell(estado_venta)="data">
          <b-badge
            v-if="data.item.fechaAnulacion == null"
            variant="success"
          >
            Vigente
          </b-badge>
          <b-badge
            v-if="data.item.fechaAnulacion != null"
            variant="danger"
          >
            Anulado
          </b-badge>

        </template>
        <template #cell(nroComprobante)="data">
          {{ data.item.serie }}-{{ data.item.numero }}
        </template>
        <template #cell(comprobanteTemplate)="data">
          <div class="d-flex align-items-center">
            <div>
              <div class="font-small-4 font-weight-bolder">
                {{ data.item.serie }}-{{ data.item.numero }}
              </div>
              <div class="font-small-3 text-muted">
                Fecha : {{ data.item.fechaEmision }}
              </div>
            </div>
          </div>
        </template>
        <template #cell(clienteDenominacion)="data">
          <div class="d-flex align-items-center">
            <div>
              <div class="font-small-3 font-weight-bolder">
                {{ data.item.clienteDenominacion }}
              </div>
              <div class="font-small-2 text-muted">
                RUC: {{ data.item.clienteNumeroDocumento }}
              </div>
            </div>
          </div>
        </template>
        <template #cell(transportistaDenominacion)="data">
          <div class="d-flex align-items-center">
            <div>
              <div class="font-small-3 font-weight-bolder">
                {{ data.item.transportistaDenominacion }}
              </div>
              <div class="font-small-2 text-muted">
                RUC: {{ data.item.transportistaDocumentoNumero }}
              </div>
            </div>
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(acciones)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="data.item.repuestaOse != null"
              target="_blank"
              :href="data.item.repuestaOse == null ?'':data.item.repuestaOse.enlace"
              @click="getPrintPdf(data)"
            >
              <feather-icon icon="PrinterIcon" />
              <span class="align-middle ml-50">PDF, XML Y CDR</span>
            </b-dropdown-item>
            <!-- <b-dropdown-item
                v-if="data.item.repuestaOse != null && data.item.fechaAnulacion == null"
                @click="generarGuia(data)"
              >
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Generar Guia</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.repuestaOse != null && data.item.fechaAnulacion == null"
                @click="generarNota(data)"
              >
                <feather-icon icon="BookIcon" />
                <span class="align-middle ml-50">Generar Nota</span>
              </b-dropdown-item> -->
            <b-dropdown-item
              v-if="data.item.repuestaOse == null"
              target="_blank"
              :href="data.item.repuestaOse == null ?'':data.item.repuestaOse.rutaPdf"
              @click="getConsultaOse(data)"
            >
              <feather-icon icon="PrinterIcon" />
              <span class="align-middle ml-50">Consultar Ose</span>
            </b-dropdown-item>

            <!-- <b-dropdown-item @click="getEdit(data)">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Ver</span>
              </b-dropdown-item>

              <b-dropdown-item @click="getEdit(data)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Editar</span>
              </b-dropdown-item>

              <b-dropdown-item @click="getDelete(data)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Eliminar</span>
              </b-dropdown-item> -->
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando {{ from }} a {{ to }} de {{ of }} registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="page"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>

import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BDropdown, BDropdownItem, BPagination, BSpinner, BAvatar, BMedia, VBTooltip, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { showToast, showError } from '@/helpers'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BBadge,

    vSelect,
    BSpinner,
    BAvatar,
    BMedia,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },

  data: () => ({
    isBusy: false,
    idEmpresa: 3,
    isToggleSidebar: false,
    dataEdit: {},
    formType: '',
    page: 1,
    perPage: 10,
    totalRows: 0,
    totalItems: 0,
    totalPage: 0,
    from: 0,
    to: 0,
    of: 0,
    pageOptions: [10, 20, 50],
    sortBy: null,
    sortDesc: false,
    sortDirection: 'asc',
    query: '',
    items: [],
    statusFilter: { id: '-1', descripcion: 'Todos' },
    statusOptions: [
      { id: '-1', descripcion: 'Todos' },
      { id: '1', descripcion: 'Enviados' },
      { id: '2', descripcion: 'Pendientes' },
      { id: '3', descripcion: 'Vigentes' },
      { id: '4', descripcion: 'Anulados' },
    ],
    userProfile: localStorage.getItem('userProfile'),
    fields: [
      { key: 'idMovimiento', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
      { key: 'fecha', label: 'FECHA', sortable: true, tdClass: 'w150', thClass: 'w150' },
      { key: 'codigoMovimiento', label: 'CODIGO MOVIMIENTO', sortable: true, tdClass: 'w180', thClass: 'w180' },
      // { key: 'nroComprobante', label: 'Nro Comprobante' },
      { key: 'motivoMovimiento.tipoMovimiento', label: 'TIPO', sortable: true },
      { key: 'almacenDestino.descripcion', label: 'DESTINATARIO' },
      { key: 'motivoMovimiento.descripcion', label: 'MOTIVO' },
      { key: 'acciones' },
    ],
  }),
  watch: {
    perPage: {
      handler() {
        this.listarRegistros()
      },
    },
    page: {
      handler() {
        this.listarRegistros()
      },
    },
    query: {
      handler() {
        this.listarRegistros()
      },
    },
  },
  mounted() {
    this.listarRegistros()
  },
  methods: {
    getPrintPdf(data) {
      if (data.item.repuestaOse !== null) {
        console.log(data.item.repuestaOse.rutaPdf)
      }
    },
    async listarRegistros() {
      console.log(this.userProfile)
      this.isBusy = true
      await store
        .dispatch('logistica/MOVIMIENTO_FIND_ALL', {
          idEmpresa: this.idEmpresa,
          tipo: 'grilla',
          limit: this.perPage,
          query: this.query,
          page: this.page,
          almacen: -1,
          sortBy: 'idMovimiento%7CDESC',
          filtro_venta: this.statusFilter.id,
        })
        .then(response => {
          this.items = response.items
          this.totalRows = response.totalRows
          this.totalItems = response.totalItems
          this.totalPage = response.totalPage
          this.from = this.perPage * (this.page - 1) + (this.totalRows ? 1 : 0)
          this.to = this.perPage * (this.page - 1) + this.totalRows
          this.of = this.totalItems
        })
        .catch(error => {
          this.showToast('Ha ocurrido un error', error, 'danger')
        })
      this.isBusy = false
    },
    openForm(type) {
      // this.formType = type
      // this.isToggleSidebar = true
      console.log(type)
      this.$router.push({ name: 'rt-logistica-movimientoform' })
    },
    getEdit(data) {
      this.formType = 'edit'
      this.isToggleSidebar = true
      console.log(data.item)
      this.dataEdit = data.item
    },
    deleteVenta(data) {
      this.$swal({
        title: 'Está seguro?',
        text: 'Va eliminar un registro!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const partComprobante = data.item.numeroComprobante.split('-')
          const params = { nro_comprobante: partComprobante[1], serie: partComprobante[0], tipo_documento: data.item.tipoDocumento.tipoDocumentoId, venta_id: data.item.ventaId }
          this.$swal({
            title: 'Anulando en la Ose',
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            onOpen: async () => {
              this.$swal.showLoading()
              const service = 'comercial/VENTA_DELETE'
              await store.dispatch(service, params)
                .then(() => {
                  // this.items.forEach((item, index) => {
                  //   if (item.ventaId === data.item.ventaId) {
                  //     this.items[index].repuestaOse = response.respuesta_ose
                  //   }
                  // })
                  this.listarRegistros()
                  this.$swal.close()
                  this.showToast('Operación Extitosa', 'El comprobante se anulo', 'success')
                })
                .catch(error => {
                  this.$swal.close()
                  console.log(error)
                  this.showToast('Operación Error', error.errorMessage, 'danger')
                })
            },
          })
        }
      })
    },
    refetchData(response) {
      let message = 'El proceso se ha ejecutado satisfactoriamente.'
      if (response.message) {
        message = response.message
      }
      this.showToast('Operación Extitosa', message, 'success')
      this.listarRegistros()
    },
    getColorAvatar() {
      const max = 7
      const min = 1
      const status = Math.floor(Math.random() * ((max + 1) - min) + min)
      if (status === 1) return 'primary'
      if (status === 2) return 'danger'
      if (status === 3) return 'secondary'
      if (status === 4) return 'warning'
      if (status === 5) return 'info'
      if (status === 6) return 'success'
      return 'primary'
    },
    // deleteVenta
    // generarGuia
    // generarNota
    // getConsultaOse
    getConsultaOse(data) {
      console.info('Consulta', data)
      const params = { nro_comprobante: data.item.numero, serie: data.item.serie, tipo_documento: data.item.tipoComprobante, venta_id: 0, guia_remision_id: data.item.guiaRemisionId }
      this.$swal({
        title: 'Consultando en la Ose',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: async () => {
          this.$swal.showLoading()
          const service = 'comercial/VENTA_CONSULTA_OSE'
          await store.dispatch(service, params)
            .then(response => {
              this.items.forEach((item, index) => {
                if (item.ventaId === data.item.ventaId) {
                  this.items[index].repuestaOse = response.respuesta_ose
                }
              })
              this.$swal.close()
              this.showToast('Operación Extitosa', 'El comprobante esta enviado en la Ose', 'success')
            })
            .catch(error => {
              this.$swal.close()
              console.log(error)
              this.showToast('Operación Error', error.errorMessage, 'danger')
            })
        },
      })
    },
    chageStatusFilter() {
      console.log(this.statusFilter)
      this.listarRegistros()
    },
    avatarText,
    showToast,
    showError,
  },

}
</script>

  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }

  .invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  </style>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>

  <style>
  .w200{
    width: 350px !important;
  }

  </style>
